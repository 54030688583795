body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-tooltip {
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border: 1px solid #ccc;
}
.result-loader {
  left: 0;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
}
.we-minhgt {
  display: block;
  min-height: 100px;
}
.p-overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9;
}
.p-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 1.6em;
  text-decoration: none;
  text-transform: uppercase;
  transition-property: color, background-color, border-color, padding-top,
    padding-bottom;
  zoom: 1;
}
.gd-card {
  min-height: 96%;
}
.custom-list li {
  list-style: none;
  background: ghostwhite;
  padding: 5px;
  border-left: 3px solid;
}
#header .p-link {
  color: #323232;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.minHeight-wc {
  min-height: 295px;
  background: white;
}
.minHeight {
  min-height: 393px;
  background: white;
}
.minHeight .item-clr {
  background: #f7f7f7;
}
#login-loader,
#register-loader, #contactus-loader {
  display: none;
}
#sharelink-loader {
  display: none;
}
#corelation-loader {
  display: none;
}
div#loginModal .btn {
  min-height: auto;
}
#error-message {
  display: none;
}

/* login/register css */
#register-form {
  display: none;
}
.panel-login > .panel-heading {
  color: #00415d;
  background-color: #fff;
  border-color: #fff;
  text-align: center;
}
.panel-login > .panel-heading p {
  text-decoration: none;
  color: #666;
  font-weight: bold;
  font-size: 15px;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.panel-login > .panel-heading p.active {
  color: #029f5b;
  font-size: 18px;
}
.panel-login > .panel-heading hr {
  margin-top: 10px;
  margin-bottom: 0px;
  clear: both;
  border: 0;
  height: 1px;
  background-image: -webkit-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
  background-image: -moz-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
  background-image: -ms-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
    left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0)
  );
}
.panel-login input[type="text"],
.panel-login input[type="email"],
.panel-login input[type="password"] {
  height: 45px;
  border: 1px solid #ddd;
  font-size: 16px;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.panel-login input:hover,
.panel-login input:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #ccc;
}
.btn-login {
  background-color: #59b2e0;
  outline: none;
  color: #fff;
  font-size: 14px;
  height: auto;
  font-weight: normal;
  padding: 14px 0;
  text-transform: uppercase;
  border-color: #59b2e6;
}
.btn-login:hover,
.btn-login:focus {
  color: #fff;
  background-color: #53a3cd;
  border-color: #53a3cd;
}
.forgot-password {
  text-decoration: underline;
  color: #888;
}
.forgot-password:hover,
.forgot-password:focus {
  text-decoration: underline;
  color: #666;
}

.btn-register {
  background-color: #1cb94e;
  outline: none;
  color: #fff;
  font-size: 14px;
  height: auto;
  font-weight: normal;
  padding: 14px 0;
  text-transform: uppercase;
  border-color: #1cb94a;
}
.btn-register:hover,
.btn-register:focus {
  color: #fff;
  background-color: #1ca347;
  border-color: #1ca347;
}
.personality-list .custom-control-input:checked ~ .custom-control-label::before,
.personality-list .custom-control-input:checked ~ .custom-control-label::after,
.personality-list .custom-control-label::after,
.personality-list .custom-control-label::before {
  position: absolute;
  left: 50% !important;
  top: -20px !important;
}
.card.personality-list .list-group-item {
  border: 0px !important;
}
.personality-list .custom-control-label {
  width: 100% !important;
}
.communication-test .list-group-item {
  border: none !important;
}
